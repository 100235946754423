<template>
  <v-container fluid class="mb-2">
    <h3 class="mt-4 mb-2">Rekam Medis</h3>

    <div class="row justify-content-center">
      <div class="col-lg-3">
        <label><b>Pilih Jenis File Rekam Medis</b></label>
        <select
          @change="getDataFileRekam()"
          v-model="jenis"
          class="form-control form-control-md"
        >
          <option value="">Pilih Jenis File</option>
          <option value="rawat_jalan">RAWAT JALAN</option>
          <option value="rawat_inap">RAWAT INAP</option>
          <option value="laboratorium">LABORATORIUM</option>
          <option value="radiologi">RADIOLOGI</option>
          <option value="hd">HEMODIALISA</option>
        </select>
      </div>
      <div class="col-lg-3">
        <label><b>Page</b></label>
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" @click="initPermintaanPreviosPage"
                >Previous</a
              >
            </li>
            <li class="page-item">
              <a class="page-link" v-html="permintaan_pagination_container"></a>
            </li>
            <li class="page-item">
              <a class="page-link" @click="initPermintaanNextPage">Next</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-lg-2">
        <v-btn type="button" color="red" dark @click="show"
          >Click to show</v-btn
        >
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div v-viewer="options" class="images clearfix">
          <!-- <img
          v-for="source in listFileRekamMedis"
          :key="source.nama_file"
          :src="source.nama_file"
          style="max-width: 150px; max-height: 250px"
          :data-source="source.nama_file"
          class="image"
        /> -->
          <v-row class="m-5">
            <v-col
              v-for="source in listFileRekamMedis"
              :key="source.id_list_pasien"
              class="d-flex child-flex"
              cols="2"
            >
              <div class="card border-light">
                <div class="card-body">
                  <p class="card-title text-center">
                    <b>{{ source.type }}</b>
                  </p>
                  <img
                    :key="source.nama_file"
                    :src="source.nama_file"
                    :data-source="source.nama_file"
                    style="max-height: 250px"
                    class="card-img-top"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-3">
        <label><b>Pilih Jenis File Rekam Medis</b></label>
        <select
          @change="getDataFileRekam()"
          v-model="jenis"
          class="form-control form-control-md"
        >
          <option value="">Pilih Jenis File</option>
          <option value="rawat_jalan">RAWAT JALAN</option>
          <option value="rawat_inap">RAWAT INAP</option>
          <option value="laboratorium">LABORATORIUM</option>
          <option value="radiologi">RADIOLOGI</option>
          <option value="hd">HEMODIALISA</option>
        </select>
      </div>
      <div class="col-lg-3">
        <label><b>Page</b></label>
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" @click="initPermintaanPreviosPage"
                >Previous</a
              >
            </li>
            <li class="page-item">
              <a class="page-link" v-html="permintaan_pagination_container"></a>
            </li>
            <li class="page-item">
              <a class="page-link" @click="initPermintaanNextPage">Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'

export default {
  data () {
    return {
      permintaans_page: 1,
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',
      listFileRekamMedis: [],
      jenis: '',
      images: [
        'http://hr.rs-syafira.com/elFinder_hr/file_get_contents.php?dir=//100.100.100.6/erm/DocMedis/006044//laboratorium/2023-06-09/laboratorium_006044_0000-00-00_2023-06-09_19-33-51_0ad0.jpg&_t=1686314031',
        'http://hr.rs-syafira.com/elFinder_hr/file_get_contents.php?dir=//100.100.100.6/erm/DocMedis/006044//laboratorium/2023-06-09/laboratorium_006044_0000-00-00_2023-06-09_19-33-52_77db.jpg&_t=1686314032',
        'http://hr.rs-syafira.com/elFinder_hr/file_get_contents.php?dir=//100.100.100.6/erm/DocMedis/006044//laboratorium/2023-06-09/laboratorium_006044_0000-00-00_2023-06-09_19-33-52_a4c2.jpg&_t=1686314032'
      ],
      options: {
        toolbar: true,
        url: 'data-source'
      }
      //   images: [...sourceImages].splice(0, 5)
    }
  },
  created () {
    this.getDataFileRekam()
  },
  methods: {
    show () {
      const viewer = this.$el.querySelector('.images').$viewer
      viewer.show()
    },
    getDataFileRekam () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/list-rekam-medis',
        {
          rm: this.$route.query.no_rm,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          jenis: this.jenis
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          const pagination = results.pagination
          this.permintaan_pagination_container = pagination
          this.permintaans_total = results.total_pages
          this.listFileRekamMedis = results.list
          // console.log(response)
        },
        'JSON'
      )
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getDataFileRekam()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getDataFileRekam()
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  // width: calc(20% - 10px);
  // cursor: pointer;
  // margin: 5px;
  // display: inline-block;
}
</style>
